/**
 * Description: JS for write-right.net
 * Version: 1.0.0
 * Last update: 2015/07/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *  0. matchMedia() polyfill
 *    - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *  1. Custom JS for write-right.net
 *
 */

/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function () {
  return this.each(function (_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({appearance: 'none', '::-ms-expand': 'none'});
    $icon.css({pointerEvents: 'none'});
  })
};


/* =============================
*  NEW CALC INIT
================================ */
$.getScript('/assets/js/calc.js', function () {
  // SHORT CALC
  if ($('[data-calc-short]').length > 0) {
    new Calc({
      ID: '40',
      calculatorType: 'ShortCalculator',
      format: 'json',
      options: {
        deafault: {
          deadlinesDefault: [110783, 110831, 110881, 110933, 110981],
          academicLevelDefault: 2,
          paperTypesDefault: 2,
          pagesDefault: 2
        }
      }
    });
  }

  //CALC MINI
  if ($('[data-calc-mini]').length > 0) {
    new Calc({
      ID: '40',
      calculatorType: 'DefaultCalculator',
      format: 'html',
      options: {
        deafault: {
          deadlinesDefault: [110783, 110831, 110881, 110933, 110981],
          academicLevelDefault: 2,
          paperTypesDefault: 2,
          pagesDefault: 2
        }
      }
    });
  }
});
/* =============================
*  end NEW CALC INIT
================================ */
/* ==========================================================================
	1. Custom JS for write-right.net
	========================================================================== */

document.addEventListener('DOMContentLoaded', function () {

  /* Toggle list
  ======================= */
  if ($('[data-type="toggle-list"]').length > 0) {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      console.log("dafsdfsd");
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }


  $(document).on('scroll', function () {
    if ($('.rc-sticky-sidebar').css('top') !== 'auto') {
      $('.rc-sticky-sidebar').css('margin-top', '50px');
    } else {
      $('.rc-sticky-sidebar').css('margin-top', 'auto');
    }
  });

});

$(function () {

  //toggle topmenu
  $('.toggle-menu').click(function () {
    $(this).toggleClass('active');
    if ($(this).next().css('display') == 'none') {
      $(this).next().show();
    } else {
      $(this).next().hide();
    }
    return false;
  });
  //toggle secmenu
  $('.toggle-menu-2').click(function () {
    $(this).toggleClass('active');
    if ($(this).next().css('display') == 'none') {
      $(this).next().show();
    } else {
      $(this).next().hide();
    }
    return false;
  });
  if ($('.topmenu-line').length > 0) {
    var tmenu = $('.topmenu-line'),
      tmenuTop = $('.topmenu-line').offset().top;
  }
  $(window).scroll(function () {
    //fixed menu
    var scrlTopPos = $(window).scrollTop(),
      windowWidth = parseInt($(window).width()) + 17;
    if (windowWidth >= 968) {
      if (scrlTopPos <= tmenuTop) {
        $(tmenu).removeClass('fixed');
      } else if (scrlTopPos > tmenuTop) {
        $(tmenu).addClass('fixed');
      }
    }
  });
});

$(document).ready(function () {
  if ($('.absClouds1').length > 0) {
    var w_width = $(window).width();
    var w_height = $(window).height();
    var test_one_pos = $('.absClouds1').position();
    var test_one_pos2 = $('.absClouds1').position();
    $("html").mousemove(function (e) {
      var temp_cursor_y = e.pageY;
      var temp_cursor_x = e.pageX;
      var dist = 30;
      var res_y = test_one_pos.top + ((w_height / 3 - temp_cursor_y) / 80);
      var res_x = test_one_pos.left + ((w_width / 3 - temp_cursor_x) / 80);
      var max_y = (test_one_pos.top + dist);
      var min_y = (test_one_pos.top - dist);
      var max_x = (test_one_pos.left + dist);
      var min_x = (test_one_pos.left - dist);
      var res_y2 = test_one_pos2.top + ((w_height / 2 - temp_cursor_y) / 80);
      var res_x2 = test_one_pos2.left + ((w_width / 2 - temp_cursor_x) / 80);
      var max_y2 = (test_one_pos2.top + dist);
      var min_y2 = (test_one_pos2.top - dist);
      var max_x2 = (test_one_pos2.left + dist);
      var min_x2 = (test_one_pos2.left - dist);
      if (max_y >= res_y && res_y >= min_y) {
        $('.absClouds1').css({
          'top': res_y
        });
      }
      if (max_x >= res_x && res_x >= min_x) {
        $('.absClouds1').css({
          'right': res_x
        });
      }
      if (max_y2 >= res_y2 && res_y2 >= min_y2) {
        $('.absClouds2').css({
          'top': (-1) * res_y2
        });
      }
      if (max_x2 >= res_x2 && res_x2 >= min_x2) {
        $('.absClouds2').css({
          'left': res_x2
        });
      }
    });
  }
});


// back top button

$("#back-top").hide();

function f_to_top() {
  if ($(window).innerWidth() > 812) {
    if ($(this).scrollTop() > 100) {
      $('#back-top').fadeIn();
    } else {
      $('#back-top').fadeOut();
    }
  } else {
    if ($(this).scrollTop() > 100) {
      $('#back-top').show();
    } else {
      $('#back-top').hide();
    }
  }
  if ($(window).innerWidth() > 1200) {
    $("#back-top").css('right', $(window).innerWidth() / 2 - 630 + 'px');
  } else {
    $("#back-top").css('right', 10 + 'px');
  }

}

$('#back-top').click(function () {
  if ($(window).innerWidth() > 812) {
    $('body,html').animate({
      scrollTop: 0
    }, 800);
  } else {
    $('body,html').animate({
      scrollTop: 0
    }, 300);
  }
  return false;
});

$(window).scroll(function () {
  f_to_top();
});



(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '40',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);




(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);
})();
